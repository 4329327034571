*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background: #3b71ca;
  border: 0;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.dark input[type="range"]::-webkit-slider-thumb {
  background: #8faee0;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #a3a3a3;
}

input[type="range"]:disabled:focus::-webkit-slider-thumb {
  background: #a3a3a3;
}

input[type="range"]:disabled:active::-webkit-slider-thumb {
  background: #a3a3a3;
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: #737373;
}

.dark input[type="range"]:disabled:focus::-webkit-slider-thumb {
  background: #737373;
}

.dark input[type="range"]:disabled:active::-webkit-slider-thumb {
  background: #737373;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  background: #3b71ca;
  border: 0;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.dark input[type="range"]::-moz-range-thumb {
  background: #8faee0;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #a3a3a3;
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: #737373;
}

input[type="range"]::-moz-range-progress {
  background: #3061af;
}

input[type="range"]::-ms-fill-lower {
  background: #3061af;
}

.dark input[type="range"]::-moz-range-progress {
  background: #6590d5;
}

.dark input[type="range"]::-ms-fill-lower {
  background: #6590d5;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
  background: #3061af;
}

input[type="range"]:active::-webkit-slider-thumb {
  background: #285192;
}

.dark input[type="range"]:focus::-webkit-slider-thumb {
  background: #6590d5;
}

.dark input[type="range"]:active::-webkit-slider-thumb {
  background: #3061af;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 320px) {
  .container {
    max-width: 320px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-\[1040\] {
  z-index: 1040;
}

.z-\[1055\] {
  z-index: 1055;
}

.z-\[1070\] {
  z-index: 1070;
}

.z-\[1080\] {
  z-index: 1080;
}

.z-\[999\] {
  z-index: 999;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 1rem;
}

.box-border {
  box-sizing: border-box;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.size-1 {
  width: .25rem;
  height: .25rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.h-0 {
  height: 0;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-\[calc\(100\%-1rem\)\] {
  min-height: calc(100% - 1rem);
}

.min-h-\[calc\(100\%-200px\)\] {
  min-height: calc(100% - 200px);
}

.w-0 {
  width: 0;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-24 {
  width: 6rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[267px\] {
  max-width: 267px;
}

.max-w-\[calc\(100\%-1rem\)\] {
  max-width: calc(100% - 1rem);
}

.max-w-full {
  max-width: 100%;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-auto {
  flex-basis: auto;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50px\] {
  --tw-translate-y: -50px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.02\] {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.touch-none {
  touch-action: none;
}

.touch-pan-y {
  --tw-pan-y: pan-y;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.resize {
  resize: both;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.\!justify-between {
  justify-content: space-between !important;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-e-\[0\.25rem\] {
  border-start-end-radius: .25rem;
  border-end-end-radius: .25rem;
}

.rounded-s-\[0\.25rem\] {
  border-start-start-radius: .25rem;
  border-end-start-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-e-0 {
  border-inline-end-width: 0;
}

.border-s-0 {
  border-inline-start-width: 0;
}

.border-s-\[0\.125rem\] {
  border-inline-start-width: .125rem;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.border-secondary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(179 185 194 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-t-transparent {
  border-top-color: #0000;
}

.bg-\[\#01020399\] {
  background-color: #01020399;
}

.bg-\[\#01020999\] {
  background-color: #01020999;
}

.bg-\[\#6d6d6d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(109 109 109 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-cover {
  background-size: cover;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-4 {
  padding: 1rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[0\.4rem\] {
  padding-left: .4rem;
  padding-right: .4rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[0\.4rem\] {
  padding-top: .4rem;
  padding-bottom: .4rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-bottom {
  vertical-align: bottom;
}

.font-mono {
  font-family: ui-monospace, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.normal-case {
  text-transform: none;
}

.not-italic {
  font-style: normal;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[1\.6\] {
  line-height: 1.6;
}

.leading-\[57\.6px\] {
  line-height: 57.6px;
}

.tracking-normal {
  letter-spacing: 0;
}

.\!text-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(59 113 202 / var(--tw-text-opacity)) !important;
}

.text-current {
  color: currentColor;
}

.text-danger {
  --tw-text-opacity: 1;
  color: rgb(220 76 100 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-info {
  --tw-text-opacity: 1;
  color: rgb(84 180 211 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.text-primary-100 {
  --tw-text-opacity: 1;
  color: rgb(227 235 247 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(20 164 77 / var(--tw-text-opacity));
}

.text-surface {
  --tw-text-opacity: 1;
  color: rgb(79 79 79 / var(--tw-text-opacity));
}

.text-warning {
  --tw-text-opacity: 1;
  color: rgb(228 161 27 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-opacity-100 {
  --tw-text-opacity: 1;
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-auto {
  text-underline-offset: auto;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.\!opacity-0 {
  opacity: 0 !important;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2 {
  --tw-shadow: 0 0 3px 0 #00000012, 0 2px 2px 0 #0000000a;
  --tw-shadow-colored: 0 0 3px 0 var(--tw-shadow-color), 0 2px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-4 {
  --tw-shadow: 0 2px 15px -3px #00000012, 0 10px 20px -2px #0000000a;
  --tw-shadow-colored: 0 2px 15px -3px var(--tw-shadow-color), 0 10px 20px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[height\] {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,_opacity\] {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-\[350ms\] {
  transition-duration: .35s;
}

.ease-\[cubic-bezier\(0\,0\,0\.15\,1\)\,_cubic-bezier\(0\,0\,0\.15\,1\)\] {
  transition-timing-function: cubic-bezier(0, 0, .15, 1), cubic-bezier(0, 0, .15, 1);
}

.ease-\[cubic-bezier\(0\.25\,0\.1\,0\.25\,1\.0\)\] {
  transition-timing-function: ease;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.lds-ellipsis, .lds-ellipsis div {
  box-sizing: border-box;
}

.lds-ellipsis {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ellipsis div {
  background: #a710f8;
  border-radius: 50%;
  width: 13.3333px;
  height: 13.3333px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 33.3333px;
}

.lds-ellipsis div:first-child {
  animation: .6s infinite lds-ellipsis1;
  left: 8px;
}

.lds-ellipsis div:nth-child(2) {
  animation: .6s infinite lds-ellipsis2;
  left: 8px;
}

.lds-ellipsis div:nth-child(3) {
  animation: .6s infinite lds-ellipsis2;
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  animation: .6s infinite lds-ellipsis3;
  left: 56px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-in {
  animation: 3s forwards fadeIn;
}

.fade-out {
  animation: 3s forwards fadeOut;
}

.empty\:hidden:empty {
  display: none;
}

.hover\:text-opacity-60:hover {
  --tw-text-opacity: .6;
}

.data-\[twe-carousel-fade\]\:z-0[data-twe-carousel-fade] {
  z-index: 0;
}

.data-\[twe-carousel-fade\]\:z-\[1\][data-twe-carousel-fade] {
  z-index: 1;
}

.data-\[popper-reference-hidden\]\:hidden[data-popper-reference-hidden] {
  display: none;
}

.data-\[twe-carousel-fade\]\:opacity-0[data-twe-carousel-fade] {
  opacity: 0;
}

.data-\[twe-carousel-fade\]\:opacity-100[data-twe-carousel-fade] {
  opacity: 1;
}

.data-\[twe-carousel-fade\]\:duration-\[600ms\][data-twe-carousel-fade] {
  transition-duration: .6s;
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-x-0, .group[data-twe-input-state-active] .group-data-\[twe-input-state-active\]\:border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-e-0 {
  border-inline-end-width: 0;
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-s-0 {
  border-inline-start-width: 0;
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-t {
  border-top-width: 1px;
}

.group[data-twe-input-state-active] .group-data-\[twe-input-state-active\]\:border-e-0 {
  border-inline-end-width: 0;
}

.group[data-twe-input-state-active] .group-data-\[twe-input-state-active\]\:border-s-0 {
  border-inline-start-width: 0;
}

.group[data-twe-input-state-active] .group-data-\[twe-input-state-active\]\:border-t {
  border-top-width: 1px;
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-solid, .group[data-twe-input-state-active] .group-data-\[twe-input-state-active\]\:border-solid {
  border-style: solid;
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:border-t-transparent, .group[data-twe-input-state-active] .group-data-\[twe-input-state-active\]\:border-t-transparent {
  border-top-color: #0000;
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:shadow-notch-1 {
  --tw-shadow: -1px 0 0 #3b71ca, 0 1px 0 0 #3b71ca, 0 -1px 0 0 #3b71ca;
  --tw-shadow-colored: -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:shadow-notch-2 {
  --tw-shadow: 0 1px 0 0 #3b71ca;
  --tw-shadow-colored: 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:shadow-notch-3 {
  --tw-shadow: 1px 0 0 #3b71ca, 0 -1px 0 0 #3b71ca, 0 1px 0 0 #3b71ca;
  --tw-shadow-colored: 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-twe-input-focused] .group-data-\[twe-input-focused\]\:shadow-white {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:transition-none {
    transition-property: none;
  }
}

@media (width >= 576px) {
  .min-\[576px\]\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .min-\[576px\]\:mt-7 {
    margin-top: 1.75rem;
  }

  .min-\[576px\]\:min-h-\[calc\(100\%-3\.5rem\)\] {
    min-height: calc(100% - 3.5rem);
  }

  .min-\[576px\]\:max-w-\[500px\] {
    max-width: 500px;
  }
}

@media (width >= 768px) {
  .md\:mr-16 {
    margin-right: 4rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-neutral-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity));
  }

  .dark\:border-white\/10 {
    border-color: #ffffff1a;
  }

  .dark\:bg-surface-dark {
    --tw-bg-opacity: 1;
    background-color: rgb(66 66 66 / var(--tw-bg-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
/*# sourceMappingURL=index.9bcd9857.css.map */
